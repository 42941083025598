import React, { useEffect, useState } from 'react';
import Header from '../../Component/Header/Header';
import Footer from '../../Component/Footer/Footer';
import DashIcon from '../../assets/Image/dash_ico1.svg';
import DashIcon2 from '../../assets/Image/dash_ico2.svg';
import { useNavigate } from 'react-router-dom';
import { get } from "../../Utils/Helpers/Http.js";

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the dashboard data when the component mounts
        const fetchDashboardData = async () => {
            try {
                const response = await get('/Dashboard/DashboardDetails');
                setDashboardData(response.data); // Assuming response.data contains the counts
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchDashboardData();
    }, []);

    return (
        <>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="com-md-12">
                        <div className="whiteOuterBox">
                            <div className="headerWhite">
                                <h1>Dashboard</h1>
                            </div>
                            <div className="contentBox">
                                <div className="row dashboardBlog">
                                    <div className="col col-lg-3 mb-4">
                                        <div className="card-body p-0">
                                            <a
                                                href=""
                                                className="text-center dashboard-card"
                                                onClick={() => navigate('/user')}
                                            >
                                                <div className="text-center">
                                                    <i className="IconBody">
                                                        <img src={DashIcon} alt="" />
                                                    </i>
                                                </div>
                                                <div>
                                                    <h2>Total Users</h2>
                                                    <h3>{dashboardData.userCount || 0}</h3>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="col col-lg-3 mb-4">
                                        <div className="card-body p-0">
                                            <a
                                                href=""
                                                className="text-center dashboard-card"
                                                onClick={() => navigate('/meal')}
                                            >
                                                <div className="text-center">
                                                    <i className="IconBody">
                                                        <img src={DashIcon2} alt="" />
                                                    </i>
                                                </div>
                                                <div>
                                                    <h2>Total Meals</h2>
                                                    <h3>{dashboardData.mealCount || 0}</h3>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                    {/* Add more cards as needed */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Dashboard;
