import axios from 'axios';
import { BASE_URL } from '../Constants/Common'
import  {getLocalStorageData}  from "../../Utils/Helpers/Helpers.js";
const API_URL = BASE_URL;

const get = async (url, params = {}) => {
    let AccessToken = getLocalStorageData('accessToken') || "";
    let Authorization = getLocalStorageData('authorizationToken') ? `${'Bearer ' + getLocalStorageData('authorizationToken')}` : "";
    let reqHeaders = {
        UtcOffsetInSecond: 19800,
        AppVersion: 1,
        AccessToken,
        Authorization
    };
    
    try {
        const response = await axios.get(`${API_URL}${url}`, { headers: reqHeaders, params });
        return response.data;
    } catch (error) {
        console.error('Error get:', error.response?.status);
        if (error.response?.status === 401) {
            removeLocal();
        }
        throw error;
    }
};

const post = async (url, data) => {
    let AccessToken = getLocalStorageData('accessToken') || "";
    let Authorization = getLocalStorageData('authorizationToken') ? `${'Bearer ' + getLocalStorageData('authorizationToken')}` : "";
    console.log("******* TOKEN ****", AccessToken, Authorization)
    let reqHeaders = {
        UtcOffsetInSecond: 19800,
        AppVersion: 1,
        AccessToken,
        Authorization
    }
    console.log("(url, data, headers", url, data)
    try {
        const response = await axios.post(`${API_URL}${url}`, data, { headers: reqHeaders });
        console.log("response*****", response);
        return response;
    } catch (error) {
        console.error('Error:', error);
        console.error('Error:POST', error.response.status);
        if (error.response.status === 401) {
            removeLocal();
        }
        else if (error.response.status === 498) {
            console.log("invalid token *****");
            removeLocal();
        }
        throw error;
    }
};

const put = async (url, data, headers = {}) => {
    try {
        const response = await axios.put(`${API_URL}${url}`, data, { headers });
        return response;
    } catch (error) {
        console.error('Error:', error);
        console.error('Error:', error.response.status);
        if (error.response.status === 401) {
            removeLocal();
        }
        throw error;
    }
};

const patch = async (url, data, headers = {}) => {
    try {
        const response = await axios.patch(`${API_URL}${url}`, data, { headers });
        return response;
    } catch (error) {
        console.error('Error:', error);
        console.error('Error:', error.response.status);
        if (error.response.status === 401) {
            removeLocal();
        }
        throw error;
    }
};

const del = async (url, headers = {}) => {
    try {
        const response = await axios.delete(`${API_URL}${url}`, { headers });
        return response;
    } catch (error) {
        console.error('Error:', error);
        console.error('Error:', error.response.status);
        if (error.response.status === 401) {
            removeLocal();
        }
        throw error;
    }
};

const removeLocal = () => {
    localStorage.removeItem("authorizationToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
}
export { get, post, put, patch, del };
