import React from 'react'
import LogoLogin from '../../assets/Image/logo_login.svg';
import LeftLogo from '../../assets/Image/left_sm_logo.svg';
import BackToLoginButton from '../../common/LoginBtn/BackToLoginButton';
import Forgotpasswordbtn from '../../common/LoginBtn/Forgotpasswpordbtn';
const InvalidToken = () => {
   
    return (
        <>
            <aside class="sidebar-login">
                <div class="loginLogoLeft">
                    <img src={LeftLogo} alt="logo" />
                    <p class="loginSubTitle">Lorem ipsum dolor sit amet,
                        onsectetur adipiscing </p>
                </div>
            </aside>
            <main class="main-body">
                <div class="content loginOuter">
                    <div class="row flex-fill g-4">
                        <div class="col-md-12 d-flex align-items-center justify-content-center">
                            <div class="outer-box loginBox">
                                <div class="loginLogoRight">
                                    <img src={LogoLogin} alt="logo" />
                                </div>
                                <div class="outer-box-header">
                                    <h1>Your link has been expired </h1>
                                </div>
                                <div className="col-md-12">
                                        <Forgotpasswordbtn />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )

}

export default InvalidToken;