// src/components/EyeIcon.js
import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const EyeIcon = ({ isVisible, onClick }) => {
  return (
    <button
      type="button"
      className="position-absolute eye-icon"
      onClick={onClick}
    >
      {isVisible ? <FaEyeSlash /> : <FaEye />}
    </button>
  );
};

export default EyeIcon;
