import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import LeftLogo from '../../assets/Image/left_sm_logo.svg';
import LoginLogo from '../../assets/Image/logo_login.svg';
import { post } from "../../Utils/Helpers/Http.js";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContainer from "../../common/Costomcontainer/CustomToastContainer .js";
import { ResetPasswordSchema } from '../../common/ValidationSchema/ValidationSchemas.js';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const token = new URLSearchParams(window.location.search).get("token");

  useEffect(() => {
    checkTokenValidity();
  }, [])

  const checkTokenValidity = async () => {
    try {
      const response = await post(`/Account/CheckResetTokenValid?token=${token}`);
      if (!response.data) {
        console.log("!response.data");
        // toast.error('Token is expired.');
       
          navigate('/invalidtoken');
      
      }
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 400) {
        console.log("entered here times");

        // toast.error('Invalid token. Please try using a new link.');
      } else {
        toast.error('An error occurred while validating the token.');
      }
        navigate('/invalidtoken');
     
    }
  };

  const handleResetPassword = async (values, { resetForm }) => {
    try {
      const payload = {
        token,
        password: values.newPassword,
        confirmPassword: values.confirmPassword
      };
      const response = await post('/Account/ResetPassword', payload);
      if (response.status === 200) {
        toast.success('Password reset successfully!');
        navigate("/thankyou")
        resetForm();
      } else if (response.status === 202) {
        toast.warning('Old and new passwords cannot be the same. Please try again.');
      }
      else {
        toast.error('Failed to reset password. Please try again.');
      }
    } catch (error) {
      toast.error('An error occurred while resetting the password.');
    }
  };

  return (
    <div className="outer">
      <aside className="sidebar-login">
        <div className="loginLogoLeft">
          <img src={LeftLogo} alt="logo" />
          <p className="loginSubTitle">Lorem ipsum dolor sit amet, consectetur adipiscing</p>
        </div>
      </aside>
      <main className="main-body">
        <div className="content loginOuter">
          <div className="row flex-fill g-4">
            <div className="col-md-12 d-flex align-items-center justify-content-center">
              <div className="outer-box loginBox">
                <div className="loginLogoRight">
                  <img src={LoginLogo} alt="logo" />
                </div>
                <div className="outer-box-header">
                  <h1>Reset Password</h1>
                </div>
                <Formik
                  initialValues={{ newPassword: '', confirmPassword: '' }}
                  validationSchema={ResetPasswordSchema}
                  onSubmit={handleResetPassword}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row g-3">
                        <div className="col-md-12">
                          <label>New Password</label>
                          <Field
                            type="password"
                            name="newPassword"
                            className="form-control input-password"
                            placeholder="Password"
                          />
                          <ErrorMessage name="newPassword" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-12">
                          <label>Confirm Password</label>
                          <Field
                            type="password"
                            name="confirmPassword"
                            className="form-control input-password"
                            placeholder="Password"
                          />
                          <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-12 d-flex align-items-center justify-content-end">
                          <div className="text-center">
                            <a href="/"><u>Back to Login</u></a>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                            disabled={disableSaveBtn}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <CustomToastContainer
                  onOpen={() => {
                    setDisableSaveBtn(true);
                    setTimeout(() => {
                      setDisableSaveBtn(false);
                    }, 5000);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ResetPassword;
