// import React from 'react';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';

// const UserDetailsModal = ({ show, onHide, user }) => {
//     return (
//         <Modal show={show} onHide={onHide}>
//             <Modal.Header closeButton>
//                 <Modal.Title>User Details</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 {user ? (
//                     <>
//                         <p><strong>Name:</strong> {user.name }</p>
//                         <p><strong>Email:</strong> {user.email || ""}</p>
//                         <p><strong>Gender:</strong> {user.gender || ""}</p>
//                         <p><strong>Height:</strong> {user.height || ""}</p>
//                         <p><strong>Weight:</strong> {user.weight || ""}</p>
//                         {/* Display a default image if profileImage is null */}
//                         <div>
//                             <strong>Profile Image:</strong>
//                             {user.profileImage ? (
//                                 <img src={user.profileImage} alt="Profile" style={{ maxWidth: '100px' }} />
//                             ) : (
//                                 <p>No Image</p>
//                             )}
//                         </div>
//                     </>
//                 ) : (
//                     <p>No user details available.</p>
//                 )}
//             </Modal.Body>
//             <Modal.Footer>
//                 <Button variant="secondary" onClick={onHide}>Close</Button>
//             </Modal.Footer>
//         </Modal>
//     );
// };

// export default UserDetailsModal;


import React from 'react';

const UserDetailsModal = ({ show, onHide, user }) => {
    if (!show) {
        return null; // Don't render the modal if show is false
    }

    return (
        <>
            <div className="custom-modal-backdrop" onClick={onHide}></div>
            <div className="modal show" id="detailView" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block', zIndex: 1050, position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <div className="modal-dialog modal-md maxWidthPopup modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">User Details</h1>
                            <button type="button" className="btn-close" aria-label="Close" onClick={onHide}></button>
                        </div>
                        <div className="modal-body contBox">
                            {user ? (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="detailBox">
                                            <h4>{
                                        user.name || ""}</h4>
                                            <p><a href={`mailto:${user.email}`}>{user.email || "No Email"}</a></p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="detailBox">
                                            <h4>Gender</h4>
                                            <p>{user.gender || "Not specified"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="detailBox">
                                            <h4>Height (in ft)</h4>
                                            <p>{user.height || "Not specified"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="detailBox">
                                            <h4>Weight (Kg)</h4>
                                            <p>{user.weight || "Not specified"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {/* <div className="detailBox">
                                            <h4>Profile Image</h4>
                                            {user.profileImage ? (
                                                <img src={user.profileImage} alt="Profile" style={{ maxWidth: '100px' }} />
                                            ) : (
                                                <p>No Image</p>
                                            )}
                                        </div> */}
                                    </div>
                                    {/* Add additional fields as needed */}
                                </div>
                            ) : (
                                <p>No user details available.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserDetailsModal;

