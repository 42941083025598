import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { post } from "../../Utils/Helpers/Http.js";
import LeftLogo from '../../assets/Image/left_sm_logo.svg';
import LoginLogo from '../../assets/Image/logo_login.svg';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContainer from '../../common/Costomcontainer/CustomToastContainer .js';
import { ForgotPasswordSchema } from '../../common/ValidationSchema/ValidationSchemas.js';

const ForgotPassword = () => {
  const [disableSendEmail, setDisableSendEmail] = useState(false)
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const payload = {
        email: values.email,
        userType: 1,
      };
      const response = await post('/Account/ForgetPassword', payload);
      if (response.data.data) {
        toast.success(" A password reset link has been successfully sent to your email address!");
        resetForm(); 
      } else {
        toast.error("Failed to send reset link. Please try again.");
      }
    } catch (error) {
      toast.error("Email not exist");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="outer">
      <aside className="sidebar-login">
        <div className="loginLogoLeft">
          <img src={LeftLogo} alt="logo" />
          <p className="loginSubTitle">Lorem ipsum dolor sit amet, consectetur adipiscing</p>
        </div>
      </aside>
      <main className="main-body">
        <div className="content loginOuter">
          <div className="row flex-fill g-4">
            <div className="col-md-12 d-flex align-items-center justify-content-center">
              <div className="outer-box loginBox">
                <div className="loginLogoRight">
                  <img src={LoginLogo} alt="logo" />
                </div>
                <div className="outer-box-header">
                  <h1>Forgot Password?</h1>
                  <p>Please enter your email address, you will receive a link to create a new password via email.</p>
                </div>
                <Formik
                  initialValues={{ email: '' }}
                  validationSchema={ForgotPasswordSchema}
                  onSubmit={(values, { resetForm }) => handleForgotPassword(values, { resetForm })}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row g-3">
                        <div className="col-md-12">
                          <label>Email</label>
                          <Field 
                            type="email" 
                            name="email" 
                            className="form-control input-email" 
                            placeholder="Email" 
                          />
                          <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-12 d-flex align-items-center justify-content-end">
                          <div className="text-center">
                            <a href="/"><u>Back to Login</u></a>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                            disabled={disableSendEmail || loading}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="col-md-12">
                        <CustomToastContainer
                            onOpen={() => {
                              setDisableSendEmail(true);
                              setTimeout(() => {
                                setDisableSendEmail(false);
                              }, 3000);
                            }}
                            onClose={() => setDisableSendEmail(false)}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword;
