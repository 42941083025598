import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import LeftLogo from '../../assets/Image/left_sm_logo.svg';
import LogoLogin from '../../assets/Image/logo_login.svg';
import { post } from "../../Utils/Helpers/Http.js";
import { setLocalStorageData } from "../../Utils/Helpers/Helpers";
import { useNavigate } from 'react-router-dom';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContainer from '../../common/Costomcontainer/CustomToastContainer .js';
import { LoginSchema } from '../../common/ValidationSchema/ValidationSchemas.js';

const Login = () => {
  const [disableLogin, setDisableLogin] = useState(false);
  const navigate = useNavigate();

  return (
    <div>
      <aside className="sidebar-login">
        <div className="loginLogoLeft">
          <img src={LeftLogo} alt="logo" />
          <p className="loginSubTitle">Lorem ipsum dolor sit amet, consectetur adipiscing</p>
        </div>
      </aside>
      <main className="main-body">
        <div className="content loginOuter">
          <div className="row flex-fill g-4">
            <div className="col-md-12 d-flex align-items-center justify-content-center">
              <div className="outer-box loginBox">
                <div className="loginLogoRight">
                  <img src={LogoLogin} alt="logo" />
                </div>
                <div className="outer-box-header">
                  <h1>Login</h1>
                </div>
                <Formik
                  initialValues={{ email: '', password: '' }}
                  validationSchema={LoginSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      const reqBody = {
                        email: values.email,
                        password: values.password,
                        userType: 1
                      };
                      const response = await post('/Account/Login', reqBody);
                      console.log("response******", response);
                      const { userId, accessToken, authorizationToken, userType } = response.data.data;

                      if (userType !== 1) {
                        toast.error("Invalid credentials");
                        return;
                      }
                      setLocalStorageData("userId", userId);
                      setLocalStorageData("accessToken", accessToken);
                      setLocalStorageData("authorizationToken", authorizationToken);
                      navigate("/dashboard");
                    } catch (error) {
                      toast.error("Invalid email or password.");
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row g-3">
                        <div className="col-md-12">
                          <label>Email</label>
                          <Field
                            type="text"
                            name="email"
                            className="form-control input-email"
                            placeholder="Email"
                          />
                          <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-12">
                          <label>Password</label>
                          <Field
                            type="password"
                            name="password"
                            className="form-control input-password"
                            placeholder="Password"
                          />
                          <ErrorMessage name="password" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-12 d-flex align-items-center justify-content-end">
                          <div className="text-center">
                            <a href="/forgotpassword"><u>Forgot password?</u></a>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                            disabled={disableLogin}
                          >
                            Login
                          </button>
                        </div>
                        <div className="col-md-12"></div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <CustomToastContainer
          onOpen={() => {
            setDisableLogin(true);
            setTimeout(() => {
              setDisableLogin(false);
            }, 3000);
          }} />
      </main>
    </div>
  );
};

export default Login;
