import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Header from '../../Component/Header/Header';
import Footer from '../../Component/Footer/Footer';
import ArrowLeft from '../../assets/Image/arrow-left.svg';
import TagsInput from '../../common/TagsInput/TagsInput.js';
import { AddMealSchema } from '../../common/ValidationSchema/ValidationSchemas.js';
import { useNavigate } from 'react-router-dom';
import { get, post } from "../../Utils/Helpers/Http.js";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContainer from '../../common/Costomcontainer/CustomToastContainer .js';
import Image from "../../assets/Image/add_img.svg"


const AddMeal = () => {
  const [ingredients, setIngredients] = useState([]);
  const [allergyContent, setAllergyContent] = useState([]);
  const [tags, setTags] = useState([]);
  const [mealData, setMealData] = useState(null);
  const [cuisines, setCuisines] = useState([]);
  const [meals, setMeals] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    const fetchCuisines = async () => {
      try {
        const response = await get('/Meal/GetCuisineList');
        if (response && Array.isArray(response)) {
          setCuisines(response);
        } else {
          console.warn("No data found in response or response is not an array");
        }
      } catch (error) {
        console.error('Failed to fetch cuisines:', error);
      }
    };
    const fetchMeals = async () => {
      try {
        const response = await get('/Meal/GetMealTypeList');
        if (response && Array.isArray(response)) {
          setMeals(response);
        } else {
          console.warn("No data found in response or response is not an array");
        }
      } catch (error) {
        console.error('Failed to fetch cuisines:', error);
      }
    };
    fetchMeals();
    fetchCuisines();
  }, []);
  useEffect(() => {
    const fetchMealDetails = async () => {
      const mealId = localStorage.getItem('MealId');
      console.log("mealID", mealId);
      
      if (mealId) {
        try {
          const response = await get(`/Meal/GetMealDetailsById?mealId=${mealId}`);
          if (response) {
            console.log("get meal by id response ", response)
            setMealData(response);
            setIngredients(response.ingredients || []);
            setAllergyContent(response.allergyContentTags || []);
            setTags(response.mealTags || []);
          } else {
            console.warn("No data found for the provided meal ID");
          }
        } catch (error) {
          console.error('Failed to fetch meal details:', error);
        }
      }
    };

    fetchMealDetails();
  }, []);
  const handleSubmit = async (values) => {
    const mealId = localStorage.getItem('MealId') ? parseInt(localStorage.getItem('MealId'), 10) : 0;
console.log(mealId, "dddd");

    const payload = {
      id: mealId,
      mealTitle: values.mealTitle.trim(),
      cuisine: parseInt(values.cuisine_id, 10),
      mealType: parseInt(values.mealType, 10),
      fromPrice: parseFloat(values.priceFrom, 10),
      toPrice: parseFloat(values.priceTo, 10),
      fileUrl: values.fileUrl || "",
      calories: parseFloat(values.calories, 10),
      carbohydrates: parseFloat(values.carbohydrates, 10),
      proteins: parseFloat(values.proteins, 10),
      fats: parseFloat(values.fats, 10),
      fiber: parseFloat(values.fiber, 10),
      sugar: parseFloat(values.sugar, 10),
      vitamins: values.vitamins.trim() || "",
      minerals: values.minerals.trim() || "",
      description: values.description.trim() || "",
      isActive: true,
      allergyContentTags: allergyContent || [],
      ingredients: ingredients || [],
      mealTags: tags || []
    };

    try {
      const response = await post('/Meal/AddUpdateMeal', payload);
      if (response) {
cancel();

      } else {
        console.error("Failed to add meal", response.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error("Meal fields sre required")
        console.error("Error response", error.response.data);
      } else if (error.request) {
        console.error("Error request", error.request);
      } else {
        console.error("General error", error.message);
      }
      console.error("Error config", error.config);
    }
  };
  const cancel =() =>{
    console.log("meal id ");
    
    localStorage.removeItem('MealId');
    navigate("/meal")
  }
console.log("meal data ", mealData)
// function handleNumberInput(event) {
//   const value = event.target.value;
//   if (/^\d*\.?\d*$/.test(value)) {
//     event.target.value = value;
//   } else {
//     event.preventDefault();
//   }
// }
function handleNumberInput(event) {
  const value = event.target.value;
  const decimalPattern = /^\d{0,5}(\.\d{0,2})?$/;

  if (decimalPattern.test(value)) {
    // Remove any invalid characters
    event.target.value = value;
  } else {
    // Prevent invalid input
    event.preventDefault();
  }
}


  return (
    <>
      <Header />
      <div className="container">
        <Formik
        enableReinitialize
          initialValues={{
            mealTitle: mealData?.mealTitle || '',
            cuisine_id: mealData?.cuisine || '', 
            mealType: mealData?.mealType || '',
            priceFrom: mealData?.fromPrice || '',
            priceTo: mealData?.toPrice || '',
            calories: mealData?.calories || '',
            carbohydrates: mealData?.carbohydrates || '',
            proteins: mealData?.proteins || '',
            fats: mealData?.fats || '',
            fiber: mealData?.fiber || '',
            sugar: mealData?.sugar || '',
            vitamins: mealData?.vitamins || '',
            minerals: mealData?.minerals || '',
            description: mealData?.description || '',
            // image: mealData?.fileUrl || '', 
            isActive: mealData?.isActive || false,
            allergyContentTags: mealData?.allergyContentTags || [],
            ingredients: mealData?.ingredients || [],
            mealTags: mealData?.mealTags || [],
          }}
          validationSchema={AddMealSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="row">
                <div className="com-md-12">
                  <a
                    className="backBtn"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.removeItem('MealId');
                      navigate('/meal');
                    }}
                  >
                    <i><img src={ArrowLeft} alt="" /></i> Back
                  </a>
                </div>
                <div className="com-md-12">
                  <div className="whiteOuterBox">
                    <div className="headerWhite"> <h1>{mealData ? 'Edit Meal' : 'Add Meal'}</h1></div>
                    <div className="contentBox">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label>Meal Title <span style={{ color: 'red' }}>*</span></label>
                            <Field type="text" name="mealTitle" className="form-control" placeholder="Meal Title" maxLength={50} />
                            <ErrorMessage name="mealTitle" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label >Select Cuisine <span style={{ color: 'red' }}>*</span></label>
                            <Field as="select" name="cuisine_id" className="form-select">
                              <option value="" label="Select Cuisine" />
                              {cuisines?.map(cuisine => (
                                <option key={cuisine.id} value={cuisine.id}>
                                  {cuisine.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage name="cuisine_id" component="div" className="error" />
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label>Meal Type <span style={{ color: 'red' }}>*</span></label>
                            <Field as="select" name="mealType" className="form-select">
                              <option value="" label="Select Meal" />
                              {meals?.map(meal => (
                                <option key={meal.id} value={meal.id}>
                                  {meal.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage name="mealType" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="row">
                            <div className="col-lg-12">
                              <label>Price Range <span style={{ color: 'red' }}>*</span></label>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <Field type="text" name="priceFrom" className="form-control" placeholder="From" maxLength={5}   onInput={handleNumberInput}/>
                                <ErrorMessage name="priceFrom" component="div" className="error" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <Field type="text" name="priceTo" className="form-control" placeholder="To" maxLength={5}   onInput={handleNumberInput}/>
                                <ErrorMessage name="priceTo" component="div" className="error" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="mb-3">
                            <div className="uploadImageBox">
                              <label htmlFor="uploadImageVid" className="attachFileBox smDocumentUpload">
                                <i>
                                  <img className="uploadAddIcon" src={Image} />
                                   </i>
                                <input type="file" id="uploadImageVid" className="attachFileHideInput"
                                  onChange={(event) => setFieldValue('image', event.currentTarget.files[0])} />
                              </label>
                              <ErrorMessage name="image" component="div" className="error" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="borderTop"></div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <h2 className="subTitle">Add Nutritional</h2>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label className="me-1">Calories</label>
                              <Field type="text" name="calories" className="form-control me-1 inputMaxWidth" placeholder=""  maxLength={5}   onInput={handleNumberInput}/>
                              <span>Gms</span>
                            </div>
                            <ErrorMessage name="calories" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label className="me-1">Carbohydrates</label>
                              <Field type="text" name="carbohydrates" className="form-control me-1 inputMaxWidth" placeholder=""  maxLength={5}   onInput={handleNumberInput}/>
                              <span>Gms</span>
                            </div>
                            <ErrorMessage name="carbohydrates" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label className="me-1">Proteins</label>
                              <Field type="text" name="proteins" className="form-control me-1 inputMaxWidth" placeholder="" maxLength={5}   onInput={handleNumberInput}/>
                              <span>Gms</span>
                            </div>
                            <ErrorMessage name="proteins" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label className="me-1">Fats</label>
                              <Field type="text" name="fats" className="form-control me-1 inputMaxWidth" placeholder="" maxLength={5}   onInput={handleNumberInput}/>
                              <span>Gms</span>
                            </div>
                            <ErrorMessage name="fats" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label className="me-1">Fiber</label>
                              <Field type="text" name="fiber" className="form-control me-1 inputMaxWidth" placeholder="" maxLength={5}   onInput={handleNumberInput}/>
                              <span>Gms</span>
                            </div>
                            <ErrorMessage name="fiber" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label className="me-1">Sugar</label>
                              <Field type="text" name="sugar" className="form-control me-1 inputMaxWidth" placeholder="" maxLength={5}   onInput={handleNumberInput}/>
                              <span>Gms</span>
                            </div>
                            <ErrorMessage name="sugar" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                          <div className="smInputBoxs">
                            <label>Vitamins</label>
                            <Field type="text" name="vitamins" className="form-control inputMaxWidth" placeholder="Vitamins" maxLength={50} />
                            <ErrorMessage name="vitamins" component="div" className="error" />
                          </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                          <div className="smInputBoxs">
                            <label>Minerals</label>
                            <Field type="text" name="minerals" className="form-control inputMaxWidth" placeholder="Minerals" maxLength={50} />
                            <ErrorMessage name="minerals" component="div" className="error" />
                          </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label>Allergy Content <span style={{ color: 'red' }}>*</span></label>
                            <TagsInput
                              tags={allergyContent}
                              setTags={setAllergyContent}
                              placeholder="Press Enter to add an allergy content"
                            />
                            <ErrorMessage name="allergyContent" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label>Ingredients <span style={{ color: 'red' }}>*</span></label>
                            <TagsInput
                              tags={ingredients}
                              setTags={setIngredients}
                              placeholder="Press Enter to add an ingredient"
                            />
                            <ErrorMessage name="ingredients" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label>Tags</label>
                            <TagsInput
                              tags={tags}
                              setTags={setTags}
                              placeholder="Press Enter to add a tag"
                            />
                            <ErrorMessage name="tags" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label>Description <span style={{ color: 'red' }}>*</span></label>
                            <Field as="textarea" name="description" className="form-control messageBox" placeholder="Description" maxLength={500} />
                            <ErrorMessage name="description" component="div" className="error" />
                          </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-12">
                        <div className="mb-3 text-end">
                        <button type="button" className="btn btn-outline me-2" onClick={cancel}>
  Cancel
</button>

                          <button type="submit" className="btn btn-primary">{mealData ? 'Update' : 'Save'}</button>

                        </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <CustomToastContainer/>
      <Footer />
    </>
  );
};

export default AddMeal;
