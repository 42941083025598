import React from 'react'
import LogoLogin from '../../assets/Image/logo_login.svg';
import LeftLogo from '../../assets/Image/left_sm_logo.svg';
import BackToLoginButton from '../../common/LoginBtn/BackToLoginButton';
import { useNavigate } from 'react-router-dom';
import { post } from "../../Utils/Helpers/Http.js";
import { useEffect } from 'react';
const EmailVerification = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Extract the token from the query parameters
        const token = new URLSearchParams(window.location.search).get("token");

        if (!token) {
            navigate('*');
            return;
        }

        const verifyEmail = async () => {
            const body = {
                token, 
                type: 1
            };

            try {
                const response = await post('/Account/EmailVerification', body);
                if (response.status === 200) {
                    navigate('/emailverified'); 
                } else {
                    navigate('/emailnotverified'); 
                }
            } catch (error) {
                console.error('Email verification failed:', error);
                navigate('/emailnotverified'); 
            }
        };

        verifyEmail();
    }, [navigate]);

   
    return (
        <>
            <aside class="sidebar-login">
                <div class="loginLogoLeft">
                    <img src={LeftLogo} alt="logo" />
                    <p class="loginSubTitle">Lorem ipsum dolor sit amet,
                        onsectetur adipiscing </p>
                </div>
            </aside>
            <main class="main-body">
                <div class="content loginOuter">
                    <div class="row flex-fill g-4">
                        <div class="col-md-12 d-flex align-items-center justify-content-center">
                            <div class="outer-box loginBox">
                                <div class="loginLogoRight">
                                    <img src={LogoLogin} alt="logo" />
                                </div>
                                <div class="outer-box-header">
                                    <h1>Email verfication</h1>
                                </div>
                                <div className="col-md-12">
                                        <BackToLoginButton />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )

}

export default EmailVerification;