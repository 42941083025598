import React from "react";
import "../Footer/Footer.css"
const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div class="container">
            <div class="row">
                <div class="com-md-12">
                    <div class="copy">
                        <span>&copy; Copyright {currentYear} Nutro Kiddo . | All Rights Reserved.</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;