import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ChangePasswordSchema } from '../../common/ValidationSchema/ValidationSchemas.js';
import Header from '../../Component/Header/Header';
import { post } from '../../Utils/Helpers/Http.js';
import EyeIcon from './EyeIcon.js';
import { useNavigate } from 'react-router-dom';

const Changepassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate()
  const handleChangePassword = async (values, { resetForm }) => {
    try {
      const payload = {
        oldPassword: values.oldPassword,
        password: values.newPassword,
        confirmPassword: values.confirmPassword,
      };

      const response = await post('/Account/ChangePassword', payload);

      if (response.data) {
        toast.success('Password changed successfully!'); 
        resetForm();
        setTimeout(() => {
          localStorage.clear();
          document.body.classList.remove('inner');
          document.body.classList.add('outer');
          navigate('/'); 
        }, 3000); 
      } else if (response.data.message) {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error:', error.response?.data?.message || 'An error occurred.');
      toast.error(error.response?.data?.message || 'An error occurred.');
    }
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteOuterBox">
              <div className="headerWhite">
                <h1>Change Password</h1>
              </div>
              <div className="contentBox">
                <div className="row">
                  <div className="col-lg-4 offset-lg-4">
                    <Formik
                      initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
                      validationSchema={ChangePasswordSchema}
                      onSubmit={handleChangePassword}
                    >
                      {() => (
                        <Form>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3 position-relative">
                                <label>Old Password</label>
                                <div style={{ position: "relative" }}>
                                  <Field
                                    type={showOldPassword ? 'text' : 'password'}
                                    name="oldPassword"
                                    className="form-control"
                                    placeholder="Old Password"
                                  />

                                  <EyeIcon
                                    isVisible={showOldPassword}
                                    onClick={() => setShowOldPassword(!showOldPassword)}
                                  />
                                </div>
                                <ErrorMessage name="oldPassword" component="div" className="text-danger" />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="mb-3 position-relative">
                                <label>New Password</label>
                                <div style={{ position: "relative" }}>
                                  <Field
                                    type={showNewPassword ? 'text' : 'password'}
                                    name="newPassword"
                                    className="form-control"
                                    placeholder="New Password"
                                  />
                                  <EyeIcon
                                    isVisible={showNewPassword}
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                  />
                                </div>
                                <ErrorMessage name="newPassword" component="div" className="text-danger" />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="mb-3 position-relative">
                                <label>Confirm Password</label>
                                <div style={{ position: "relative" }}>
                                  <Field
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    name="confirmPassword"
                                    className="form-control"
                                    placeholder="Confirm Password"
                                  />
                                  <EyeIcon
                                    isVisible={showConfirmPassword}
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                  />
                                </div>
                                <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="mb-3 text-end">
                                <button
                                  type="button"
                                  className="btn btn-outline me-2"
                                  onClick={() => navigate('/dashboard')}
                                >
                                  Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">Save</button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Changepassword;
