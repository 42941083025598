import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import HeaderLogo from '../../assets/Image/header_logo.svg';
import ProfileIcon from '../../assets/Image/profile_icon.svg';
import LockIcon from '../../assets/Image/lock_ico.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import Logout from '../../assets/Image/logout_ico.svg';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const removeMealId = () => {
    localStorage.removeItem('MealId');
  };
  return (
    <header>
      <nav className="navbar navbar-expand-lg bg-body-white topMenu">
        <div className="container-fluid">
          <a className="navbar-brand header-logo" href="#">
            <img src={HeaderLogo} alt="logo" />
          </a>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample11" aria-controls="navbarsExample11" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse d-lg-flex justify-content-between" id="navbarsExample11">
            <div>&nbsp;</div>
            <ul className="navbar-nav col-lg-6 justify-content-lg-center manuBar">
              <li className="nav-item">
                <NavLink className="nav-link" to="/dashboard"  onClick={removeMealId} >Dashboard</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/user"  onClick={removeMealId} >User Management</NavLink>
              </li>
              <li className="nav-item">
                <NavLink 
                  className={`nav-link ${location.pathname.includes('/meal') || location.pathname.includes('/addmeal') ? 'active' : ''}`} 
                  to="/meal"
                >
                  Meal Management
                </NavLink>
              </li>
            </ul>

            <Dropdown >
              <Dropdown.Toggle style={{ paddingRight: '10px' }} id="dropdown-basic" className="custom-dropdown-toggle">
                <img src={ProfileIcon} alt="Profile" /> Admin DS
              </Dropdown.Toggle>

              <Dropdown.Menu  style={{ minWidth: '180px' }}>
                <Dropdown.Item as={NavLink} to="/changepassword">
                  <img src={LockIcon} alt="Change Password" /> Change Password
                </Dropdown.Item>
                <Dropdown.Item
                 style={{ color: '#fb7260' }}
                className='logout drp-item'
                  onClick={() => {
                    localStorage.clear();
                    document.body.classList.remove('inner');
                    document.body.classList.add('outer');
                    navigate("/");
                  }}
                >
                  <img src={Logout} alt="Logout"  /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;

