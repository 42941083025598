import React from 'react';
import { useNavigate } from 'react-router-dom';

const Forgotpasswordbtn = () => {
  const navigate = useNavigate();

  const handleforgotpassword = () => {
    navigate('/forgotpassword');
  };

  return (
    <button
      type="button"
      className="btn btn-primary w-100"
      onClick={handleforgotpassword}
    >
      Back to forgot password
    </button>
  );
};

export default Forgotpasswordbtn;
    ;
