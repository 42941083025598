import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

// Assume this is a placeholder for actual authentication logic
const isAuthenticated = () => {
    return localStorage.getItem('authorizationToken') !== null;
};

const ProtectedRoute = ({ element }) => {
    useEffect(() => {
        if (isAuthenticated()) {
            console.log("isAuthenticated", isAuthenticated);
            document.body.classList.remove('outer');
            document.body.classList.add('inner');

        } else {
            document.body.classList.remove('inner');
        }

        return () => {
            document.body.classList.remove('inner');
        };
    }, []);

    return isAuthenticated() ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
