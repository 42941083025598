import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackToLoginButton = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/');
  };

  return (
    <button
      type="button"
      className="btn btn-primary w-100"
      onClick={handleLogin}
    >
      Back to Login
    </button>
  );
};

export default BackToLoginButton;
