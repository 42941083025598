import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ConfirmStatusChangeModal = ({ show, onHide, onConfirm, isUserActive }) => {
    console.log("show, onHide, onConfirm",isUserActive)
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Change User Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                Are you sure you want to {isUserActive ? 'block' : 'unblock'} this user?
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-outline' variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmStatusChangeModal;
