import React from 'react'
import LogoLogin from '../../assets/Image/logo_login.svg';
import LeftLogo from '../../assets/Image/left_sm_logo.svg';
import BackToLoginButton from '../../common/LoginBtn/BackToLoginButton';

const Error = () => {
  return (
    <>
      <aside class="sidebar-login">
        <div class="loginLogoLeft">
          <img src={LeftLogo} alt="logo" />
          <p class="loginSubTitle">Lorem ipsum dolor sit amet,
            onsectetur adipiscing </p>
        </div>
      </aside>
      <main class="main-body">
        <div class="content loginOuter">
          <div class="row flex-fill g-4">
            <div class="col-md-12 d-flex align-items-center justify-content-center">
              <div class="outer-box loginBox">
                <div class="outer-box-header">
                  <div class="loginLogoRight">
                    <img src={LogoLogin} alt="logo" />
                  </div>
                  <div class="outer-box-header">
                    <h2>404</h2>
                  </div>
                  <h1>Page Not Found</h1>
                  <p>Sorry, we can't find the page you're looking for !!!</p>
                </div>
                <div className="col-md-12">
                 <BackToLoginButton/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Error