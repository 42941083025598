// Utility function to store data in localStorage
const setLocalStorageData = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
}

// Utility function to retrieve data from localStorage
const getLocalStorageData = (key) => {
    const data = localStorage.getItem(key);
    console.log("local storage get data****", typeof data)
    return data ? JSON.parse(data) : null;
    // return data ? (typeof data === "string" ? data : JSON.parse(data)) : null;
}

export { setLocalStorageData, getLocalStorageData }