import React, { useState, useEffect } from 'react';
import Header from '../../Component/Header/Header';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../Component/Footer/Footer';
import Meal from '../../assets/Image/img-meals.png'
import { get, post } from "../../Utils/Helpers/Http.js";
import DeleteConfirmationModal from './DeleteConfirmationModal.js';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContainer from '../../common/Costomcontainer/CustomToastContainer .js';


const MealManagement = () => {
  const [cuisines, setCuisines] = useState([]);
  const [mealTypes, setMealTypes] = useState([]);
  const [meals, setMeals] = useState([]);
  // State for filters
  const [titleSearchKeyword, setTitleSearchKeyword] = useState("");
  const [selectedCuisine, setSelectedCuisine] = useState("");
  const [selectedMealType, setSelectedMealType] = useState("");
  const [price, setPrice] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [mealIdToDelete, setMealIdToDelete] = useState(null);
  const navigate = useNavigate();

  const fetchCuisinesAndMealTypes = async () => {
    try {
      const cuisineResponse = await get('/Meal/GetCuisineList');
      const mealTypeResponse = await get('/Meal/GetMealTypeList');
      if (cuisineResponse) {
        setCuisines(cuisineResponse);
      }

      if (mealTypeResponse) {
        setMealTypes(mealTypeResponse);
      }
    } catch (error) {
      console.error('Error fetching cuisines or meal types:', error);
    }
  };
  // const fetchMeals = async () => {
  //   try {
  //     const response = await get(
  //       `/Meal/MealList?PageNo=1&PageSize=10&TitleSearchKeyword=${titleSearchKeyword}&Cuisine=${selectedCuisine}&MealType=${selectedMealType}&Price=${price}`
  //     );
  //     if (response && response.data) {
  //       setMeals(response.data); // Assuming the API response structure
  //     }
  //   } catch (error) {
  //     setMeals([]);
  //     console.error('Error fetching meals:', error);
  //   }
  // };

  const fetchMeals = async (filters = {}) => {
    const { titleSearchKeyword = '', selectedCuisine = '', selectedMealType = '', price = '' } = filters;

    try {
      const response = await get(
        `/Meal/MealList?PageNo=1&PageSize=10&TitleSearchKeyword=${titleSearchKeyword}&Cuisine=${selectedCuisine}&MealType=${selectedMealType}&Price=${price}`
      );
      if (response && response.data) {
        setMeals(response.data); // Assuming the API response structure
      }
    } catch (error) {
      setMeals([]);
      console.error('Error fetching meals:', error);
    }
  };

  useEffect(() => {
    fetchMeals()
    fetchCuisinesAndMealTypes();
  }, []);

  const handleSearch = () => {
    fetchMeals({
      titleSearchKeyword,
      selectedCuisine,
      selectedMealType,
      price
    });
  };
  // useEffect(() => {
  //   fetchMeals();
  //   fetchCuisinesAndMealTypes();
  // }, []);
  // const handleSearch = () => {
  //   fetchMeals();
  // };
  const handleShowDeleteModal = (mealId) => {
    setMealIdToDelete(mealId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setMealIdToDelete(null);
  };
  const handleConfirmDelete = async () => {
    if (mealIdToDelete) {
      try {
        const response = await post(`/Meal/DeleteMeal?mealId=${mealIdToDelete}`);
        console.log('Delete Response:', response);
        fetchMeals(); // Refresh the meals list after deletion
        toast.success("Meal deleted successfully")

      } catch (error) {
        console.error('Error deleting meal:', error.response?.data || error.message);
        alert('Failed to delete meal. Please try again.');
      }
      handleCloseDeleteModal(); // Close the modal after deletion
    }
  };
  const handleReset = () => {
    setTitleSearchKeyword('');
    setSelectedCuisine('');
    setSelectedMealType('');
    setPrice('');

    // Fetch meals without filters
    fetchMeals();
  };
  // const handleReset = () => {
  //   setTitleSearchKeyword('');
  //   setSelectedCuisine('');
  //   setSelectedMealType('');
  //   setPrice('');
  //   fetchMeals();
  // };
  const handleEditMeal = (mealId) => {
    localStorage.setItem("MealId",mealId)
    navigate(`/addmeal?mealId=${mealId}`);
  };
  const handleNumberInput = (event) => {
    // Replace non-numeric characters
    event.target.value = event.target.value.replace(/[^0-9]/g, '').slice(0, 5); }
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row">
          <div className="com-md-12">
            <div className="whiteOuterBox">
              <div className="headerWhite"> <h1>Meal Management</h1>
                <Link to="/addmeal" className="btn btn-primary">Add Meal</Link>
              </div>

              <div className="contentBox">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="mb-3">
                      <label>Select Title</label>
                      <input type="text" className="form-control" placeholder="Search by Title"
                        value={titleSearchKeyword} 
                        // onChange={(e) => setTitleSearchKeyword(e.target.value)} 
                       onChange={(e) => {
                         const newValue = e.target.value;
                         if (newValue.length <= 50) {
                           setTitleSearchKeyword(newValue);
                          }
                        }}
                        /> 
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="mb-3">
                      <label> Select Cuisine</label>
                      <select
                        className="form-select"
                        value={selectedCuisine}
                        onChange={(e) => setSelectedCuisine(e.target.value)}
                      >
                        <option value="">Select</option>
                        {cuisines.map((cuisine) => (
                          <option key={cuisine.id} value={cuisine.id}>
                            {cuisine.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="mb-3">
                      <label>Meal Type</label>
                      <select
                        className="form-select"
                        value={selectedMealType}
                        onChange={(e) => setSelectedMealType(e.target.value)}
                      >
                        <option value="">Select</option>
                        {mealTypes.map((mealType) => (
                          <option key={mealType.id} value={mealType.id}>
                            {mealType.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="mb-3">
                      <label>Price</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Price"
                        value={price}
                        onInput={handleNumberInput}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3 mt-4">
                      <a href="#" className="btn btn-primary me-3"  onClick={handleSearch}>
                        Search
                      </a>
                      <a href="#" className="btn btn-primary" onClick={handleReset}>
                        Reset
                      </a>
                    </div>
                  </div>
               
                </div>

                <div className="row mt-3">
                  {meals.length > 0 ? (
                    meals.map((meal) => (
                      <div className="col-lg-4" key={meal.id}>
                        <div className="cardBlog">
                          <div className="imgLeft"><img src={Meal} alt="" /></div>
                          <div className="contRight">
                            <h4>{meal.title}</h4>
                            <p>Cuisine : <span>{meal.cuisine}</span></p>
                            <p>Meal Type : <span>{meal.mealType}</span></p>
                            <p>Price : <span>{meal.priceRange}</span></p>
                          </div>
                          <div className="btnsBox">
                          <a href="#" className="btnNormal btnEdit me-2" onClick={() => handleEditMeal(meal.id)}>Edit</a>

                            <a href="#" className="btnNormal btnDelete" onClick={() => handleShowDeleteModal(meal.id)}>Delete</a>

                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-lg-12" style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", height: "100vh", paddingTop: "20px" }}>
                    <p>No meals found</p>
                  </div>
                  

                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        show={showDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
      />
      <CustomToastContainer/>
      <Footer />
    </div>
  )
}
export default MealManagement;